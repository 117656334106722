export const GET_ALL_DIVISION = "/divisions";
export const GET_DISTRICTS_BY_DIVISION = "/districts-division/";
export const GET_UNITS_BY_DISTRICT = "/units-district/";
export const VERIFY_UNIQUE_USER = "/search-user";
export const REGISTER_USER = "/register";
export const LOGIN_USER = "/login";
export const LOGOUT_USER = "/logout";
export const GET_MEMBERS_BY_UNIT = "/members/";
export const UPDATE_MEMBERS_BY_UNIT = "/members/";
export const SAVE_REPORT = "/reports";
export const GET_REPORT_BY_DIVISION = "/report-division";
export const GET_REPORT_BY_SINGLE_DIVISION = "/report-division-single";
export const GET_TOTAL_REPORT = "/report-total";
export const GET_TOTAL_REPORT_BY_DIVISION = "/report-total-division";
export const GET_NEW_USERS = "/new-users";
export const VERIFY_USER = "/verify-user";
export const GET_TODAY_SHORT = "/report-today-short";
export const GET_TODAY_DETAILED = "/report-today-detailed";
export const REPORT_NOT_SENT_TODAY = "/report-not-sent";
