export const config = {
    // Local ENV
    // BASE_API_URL: "http://127.0.0.1:8000/api",

    // Production ENV
    BASE_API_URL: "https://wrb.saniyat.com/api",

    // Localization Setup
    // language: "en",
};
